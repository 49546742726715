body {
	font-family: $font-family;
}
a {
	transition: all .2s ease-in-out;
}
h1, h2, h3, h4, h5, h6 {
	font-weight: 700;
  line-height: 1.2;
}
h1 {
  font-size: 1.3rem;
  @include media-breakpoint-up(xs) {
    font-size: 2.1rem;
  }
  @include media-breakpoint-up(sm) {
    font-size: 2.3rem;
  }
  @include media-breakpoint-up(md) {
    font-size: 2.5rem;
  }
}
h2 {
  font-size: 1.1rem;
  @include media-breakpoint-up(xs) {
    font-size: 1.3rem;
  }
  @include media-breakpoint-up(sm) {
    font-size: 1.7rem;
  }
  @include media-breakpoint-up(md) {
    font-size: 2rem;
  }
}
h3 {
  font-size: 1rem;
  @include media-breakpoint-up(xs) {
    font-size: 1.15rem;
  }
  @include media-breakpoint-up(sm) {
    font-size: 1.55rem;
  }
  @include media-breakpoint-up(md) {
    font-size: 1.75rem;
  }
}
p {
  font-size: 0.85rem;
  @include media-breakpoint-up(sm) {
    font-size: 0.95rem;
  }
  @include media-breakpoint-up(md) {
    font-size: 1rem;
  }
}
.navbar {
    width: 100%;
}
.navbar-toggleable-md {
	justify-content: space-between;
  align-items: center;
  .navbar-brand {
    margin-bottom: 10px;
  }
  @include media-breakpoint-up(sm) {
    //justify-content: flex-start;
    flex-direction: row;
    .navbar-brand {
      margin-bottom: 0;
    }
  }
}
.search-form {
	.search-field {
		border: none;
	}
	.search-submit {
		border-color: $brand-primary;
		background-color: $brand-primary;
		color: white;
	}
}
.entry-share-btns {
  margin-left: -.25em;
  padding-left: 0;
  list-style: none;
}
.entry-share-btns li {
  display: inline-block;
  margin: .25em;
}
.entry-share-btns a,
.entry-content .entry-share-btns a {
  font-size: .75em;
  display: block;
  padding: .25em .75em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
  color: #fff;
  border: none;
  border-radius: 6px;
  background: #666;

  background: $brand-primary;
}
.entry-share-btns a:hover,
.entry-share-btns a:focus {
  text-decoration: none;
  color: #fff;
  background: #555;
}
.entry-share-btn-icon {
  position: relative;
  top: 3px;
  display: inline-block;
  fill: #fff;
  stroke: none;
}
.entry-share-btns svg {
  width: 1em;
  height: 1em;
  vertical-align: top;
}
.entry-share-btns span {
  display: none;
}
@media (min-width: 600px) {
  .entry-share-btns span {
    display: inline-block;
    padding-left: .4em;
  }
}
.badge-secondary {
  @include badge-variant($badge-secondary-bg);
}
