.blog-first-post {
	img {
		overflow: hidden;
		object-fit: cover;
		height: 200px;
		@include media-breakpoint-down(xs) {
			height: 220px;
		}
		@include media-breakpoint-up(sm) {
			height: 300px;
		}
		@include media-breakpoint-up(md) {
			height: 340px;
		}
		@include media-breakpoint-up(lg) {
			height: 380px;
		}
		@include media-breakpoint-up(xl) {
			height: 420px;
		}
	}
}
.blog-front-posts {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: column;
	width: 100%;
	flex-flow: wrap;
	flex: 0 1 auto;
	.front-post {
		width: 50%;
    padding-left: 15px;
    padding-right: 15px;
		@include media-breakpoint-down(sm) {
			width:100%;
		}
		img {
			overflow: hidden;
			object-fit: cover;
			height: 200px;
			@include media-breakpoint-down(xs) {
				height: 230px;
			}
			@include media-breakpoint-up(sm) {
				height: 280px;
			}
			@include media-breakpoint-up(md) {
				height: 200px;
			}
			@include media-breakpoint-up(lg) {
				height: 190px;
			}
			@include media-breakpoint-up(xl) {
				height: 220px;
			}
		}
	}
}
.meta-top {
	span {
		font-size: 0.8rem;
		font-weight: 700;
	}
}