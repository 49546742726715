.sidebar-widgets, .sidebar {
	.widget {
		border: 1px solid;
		border-color: $brand-primary rgba(151,151,151,.2) rgba(151,151,151,.2) rgba(151,151,151,.2);
		padding: 15px;
		margin-bottom: 30px;
		h3 {
			border-bottom: 1px solid #efefef;
    	padding-bottom: 15px;
    	margin-bottom: 15px;
		}
	}
	ul {
		padding: 0;
		li {
			list-style: none;
			list-style-type: none;
			margin-bottom: 10px;
			&:last-child {
				margin-bottom: 0;
			}
			a {
				font-weight: 700;
			}
		}
	}
}