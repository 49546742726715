// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #136fa1;
$brand-secondary: 		#febd12;

$font-family: 			Open Sans;

$badge-secondary-bg:	$brand-secondary;