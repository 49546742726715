.banner {
	background: #111;
}
.navbar-brand {
	display: flex;
	align-items: center;
	svg {
		width: 130px;
		height: 38px;
	}
}
.main-carousel {
	width: 100%;
	margin-top: -3rem!important;
	.carousel-cell {
		width: 100%;
		&:before {
			content: "";
	    display: block;
	    width: 100%;
	    height: 100%;
	    position: absolute;
	    top: 0;
	    left: 0;
	    background-color: rgba(11,11,11,.6);
		}
	}
	.carousel-content {
		width: 100%;
	  height: 500px;
	  margin-right: 10px;
	  background: #8C8;
	  /* flex-box */
	  display: flex;
    justify-content: center;
	  align-items: center;
	  background-size: cover;
	  background-position: 50% 50%;
	  color: white;
	}
}